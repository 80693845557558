import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import BackgroundImage from "gatsby-background-image";
import * as Styled from "../../css/CardFlexStyle";
import { Link } from "gatsby";
import getLang from "../../components/scripts/getLang";
import App from "../app";
import CheckForHighlight from "../../components/scripts/checkForHightlight";
import PictureFilter from "../../components/scripts/pictureFilter";
import { SortBySeason } from "../../components/scripts/SortBySeasons";

import { NightLifeQuery } from '../../components/scripts/graphQlQuerys/nightLifeQuery' 
import { EnheterQuery } from '../../components/scripts/graphQlQuerys/enheterQuery' 
import Taggar from '../../components/taggar'
import Searchbar from "../../components/searchbar";
import Hero from '../../components/hero'
import * as Global from "../../css/globalStyle";

const Newyear = () => {
  const pageType = "Nattliv Nyår"
  let [english] = useGlobal("English");
  const [SearchKeyword, setSearchKeyword] = useState(' ');
  let heroData = getLang(english, NightLifeQuery())
  let { seoTitel, seoDescription, Synligataggar, introText } = heroData[0].node

  useEffect(() => {
    const hashtag = window.location.hash;
    if (hashtag) {
      const currentURL = window.location.href;
      const newURL = currentURL.split("#")[0];
      window.history.replaceState("", "NewYear", newURL);
    }
  });

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <Hero introText={introText} heroTema={null} data={heroData} img={'img1'} />
      <Searchbar english={english} setSearchKeyword={setSearchKeyword} SearchKeyword={SearchKeyword} />
      <Taggar mainPage="nightlife" subPage="nyår" data={Synligataggar} />
      <NightlifeGraphql SearchKeyword={SearchKeyword} data={NightLifeQuery()} english={english} pageType={pageType} />
    </App>
  );
};

export default Newyear;

const NightlifeGraphql = props => {
  let { SearchKeyword, english, pageType } = props;
  if (SearchKeyword === undefined) {
    SearchKeyword = "";
  }

  let regex = new RegExp(SearchKeyword.trim(), "gi");
  let regex2 = new RegExp("nyår", "gi");
  let nattliv = getLang(english,EnheterQuery());
  nattliv = nattliv.sort(SortBySeason)
  nattliv = CheckForHighlight(pageType, nattliv)
    .filter(edges => {
      return edges.node.sida.includes("Nattliv");
    })
    .filter(nattliv => {
      let newString = nattliv.node.skord.toString();

      return newString.match(regex) && newString.match(regex2);
    })
    .map((item, index) => {
      let orderOne = "";
      if (item.node.highlight) {
        orderOne = "orderOne";
      }
      return (
        <Link
          className={"textDecorationNone " + orderOne}
          key={index}
          to={`/nightlife/${item.node.pekaMotSlug ? item.node.pekaMotSlug : item.node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"cardFlex"}
            fluid={PictureFilter('img1', item)}
            key={index}
          >
            <Styled.FlexH1 seasonClosed={item.node.seasonClosed}>
              <Styled.H2>{item.node.namn.toUpperCase()}</Styled.H2>
            </Styled.FlexH1>
          </BackgroundImage>
        </Link>
      );
    });

  return (
    <Styled.CenterDiv>
      <Styled.FlexContainer>{nattliv}</Styled.FlexContainer>
    </Styled.CenterDiv>
  );
};
